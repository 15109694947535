.booktransfer-container {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 8, 29, 0.6);
}
.book-transfer-details-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: fit-content;
    background: #fff;
    border-radius: 10px;
}
.book-transfer-top-header {
    background: #EBF2FD;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
} 
.book-transfer-top-header h2 {
    color: #1D3071;
    font-size: 18px;
}
.book-transfer-top-header .close {
    cursor: pointer;
}
.lead-passenger-parent-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    padding: 0 2rem;
}
.lead-passenger-name label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    color: #00081d;
}
.lead-passenger-name input {
    outline: none;
    border: 1.5px solid skyblue;
    padding: 2px 10px;
    border-radius: 20px;
}
.lead-passenger-name .react-tel-input .form-control {
  border: 1.5px solid skyblue;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
}
.lead-passenger-name .react-tel-input .form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
    border: 1.5px solid skyblue;
}
.lead-passenger-name .react-tel-input .flag-dropdown {
    background-color: #fff;
    border-left: 1px solid skyblue;
    border-top: 1px solid skyblue;
    border-bottom: 1px solid skyblue;
    border-radius: 20px 0 0 20px;
}
.adults-passenger {
  background: #fff;
  border-radius: 20px;
  width: fit-content;
}
.adults-passenger P {
    margin-bottom: 10px;
    font-size: 12px;
}
.passenger-count {
    border: 1px solid #52CCFC;
    width: fit-content;
    border-radius: 20px;
}
#count-plus {
    background: #52CCFC;
    padding:2px 10px ;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
#count-minus {
    background: #52CCFC;
    padding:2px 10px ;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.adults-passenger button {
  border: none;
  outline: none;
  background: none;
}
.adults-passenger #count {
    padding: 2px 0;
    cursor: default;
    max-width: 30px;
    min-width: 30px;
}
/* ---------------------------------Arrival FLight Code ----------------------- */
.booking-flight-arrival-departure-container {
   display: flex;
   padding: 0 2rem;
}
.flight-arrival-code-time {
    display: flex;
    gap: 1rem;
}
.flight-departure-heading h2 {
    font-size: 18px;
    font-weight: 500;
    color:#1D3071;
    margin-bottom: 1rem;
}
.flight-arrival-heading h2 {
    font-size: 18px;
    font-weight: 500;
    color:#1D3071;
    margin-bottom: 10px;
}
.booking-flight-arrival-input {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}
.booking-flight-code {
    width: 30%;
}
.booking-flight-code label {
    display: block;
    font-weight: 400;
    color: #00081d;
    margin-bottom: 5px;
}
.booking-flight-code input {
    border: 1.5px solid skyblue;
    padding: 2px 10px;
    outline: none;
    border-radius: 20px;
    width: 100%;
}
.pickup-time {
    margin: 1rem 0;
    width: fit-content;
}
.pickup-time input {
    border: 1.5px solid skyblue;
    padding: 2px 10px;
    outline: none;
    border-radius: 20px;
    width: 100%;
}
.pickup-time label {
    display: block;
    font-weight: 400;
    color: #00081d;
    margin-bottom: 5px;
}
#opacity {
    opacity: 0.3;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(62%) sepia(91%) saturate(3852%) hue-rotate(179deg) brightness(99%) contrast(93%);
    cursor: pointer;
}
.pickup-time input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(62%) sepia(91%) saturate(3852%) hue-rotate(179deg) brightness(99%) contrast(93%);
    cursor: pointer;
  }
.booking-transfer-cost {
    display: flex;
    justify-content: end;
    padding: 1rem 0;
}
.booking-transfer-cost h4 {
    color: #1D3071;
    font-size: 18px;
}
.booking-transfer-btn {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
}
.booking-transfer-btn button {
    background: #05307a;
    padding: 5px 15px;
    border-radius: 20px;
    border: none;
    outline: none;
    color: #fff;
    font-weight: 500;
    height: fit-content;
}
.booking-transfer-btn #cancel{
    background: none;
    border: 1px solid #00081d;
    color: #1D3071;
    padding: 2px 8px;
}
.booking-transfer-cost-and-btn-container {
    margin-top: 1rem;
    background-color: #EBF2FD;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
}